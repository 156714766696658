<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-card-header>
      <div>
        <b-card-title>  Top 10 Highest Value Shoppers </b-card-title>
      </div>
      <b-dropdown
          variant="link"
          no-caret
          class="chart-dropdown"
          toggle-class="p-0"
      >
        <template #button-content>
          <feather-icon
              icon="FilterIcon"
              size="18"
              class="text-body cursor-pointer"
          />
        </template>
        <b-dropdown-item href="#" v-b-modal.filter-by-date-customer-performance>
          Filter By Date
        </b-dropdown-item>

      </b-dropdown>
    </b-card-header>
    <b-card-body>
      <b-row class="mx-0">
        <b-col
            md="12"
            class="revenue-report-wrapper"
        >
          <b-table striped hover :items="customers" :fields="columns">
            <template v-slot:cell(customerName)="data">
              <a style="text-decoration: underline; font-weight: bolder;" :href="'/apps/customer/'+data.item.id">{{ data.value  }}</a>
            </template>
            <template v-slot:cell(totalPoints)="data">
              {{ data.value | number('0,0') }}
            </template>
          </b-table>

        </b-col>
      </b-row>
    </b-card-body>

    <b-modal id="filter-by-date-customer-performance" title="Filter By Date" @cancel="true" @ok="getCustomerPerformanceData"   centered>
      <form action="#" class="form-horizontal"  method="post">
        <input type="hidden" name="_token" />
        <div class="form-body">
          <div class="row" >
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">Start Date:</label> <br/>
                <div >
                  <date-picker  :lang="lang" v-model="startDate" valueType="format" :first-day-of-week="1"></date-picker>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group ">
                <label class="control-label ">End Date:</label> <br/>
                <div >
                  <date-picker  :lang="lang" v-model="endDate" valueType="format" :first-day-of-week="1"></date-picker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import {mapActions} from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    DatePicker
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columns:['customerName','totalPoints'],
      startDate:'2023-07-01',
      endDate:new Date().toISOString().split('T')[0],
      transactionType:'ACCRUAL',
      customers:[]
    }
  },
  mounted (){
    this.getCustomerPerformanceData();
  },
  methods: {
    ...mapActions(["getCustomerPerformance"]),

    getCustomerPerformanceData(){
      let self = this;

      this.isLoading = true;

      this.getCustomerPerformance({startDate: this.startDate, endDate:this.endDate,transactionType:this.transactionType, limit: 10})
          .then(function (customers) {
            self.isLoading = false;
            self.customers = customers;

          })
          .catch(function (error) {

            self.isLoading = false;

            console.log(error)
          })
    },
  }
  }
</script>

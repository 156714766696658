<template>
  <div>
    <b-card
            v-if="data"
            no-body
            class="card-statistics"
    >
      <b-card-header>
        <b-card-title>Statistics</b-card-title>
        <b-card-text class="font-small-2 mr-25 mb-0">
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <div class="row mb-5">
          <div class="col-lg-4 col-md-4 col-12">
            <date-picker :disabled-date="disabledDatesFrom"  :lang="lang" v-model="dashboard_filter.start_date" valueType="format" :first-day-of-week="1"></date-picker> to
            <date-picker :disabled-date="disabledDatesFrom"  :lang="lang" v-model="dashboard_filter.end_date" valueType="format" :first-day-of-week="1"></date-picker> &nbsp;&nbsp;
          </div>
          <div class="col-md-8">
            <button type="button" class="btn btn-danger float-left" @click="getHomepageData">Filter</button>
          </div>
        </div>

        <b-row>
          <b-col
                  xl="3"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-primary"
                >
                  <feather-icon
                          size="24"
                          icon="TrendingUpIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  KES. {{ stats.redeemable_value | number('0,0') }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Loyalty Balance
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
                  xl="3"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-success"
                >
                  <feather-icon
                          size="24"
                          icon="DollarSignIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.total_points_accrued | number('0,0') }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Accrued Points - Pending
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
                  xl="3"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-danger"
                >
                  <feather-icon
                          size="24"
                          icon="BoxIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  KES. {{ stats.redeemed_value | number('0,0') }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Redeemed Value
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
                  xl="3"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-info"
                >
                  <feather-icon
                          size="24"
                          icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.total_customers | number('0,0') }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  New Registered Customers
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <br/>
    <b-card
            v-if="data"
            no-body
            class="card-statistics"
    >
      <b-card-body class="statistics-body">
        <b-row class="mt-5 mb-5">
          <b-col
                  xl="4"
                  sm="6"
                  class="mb-2 mb-xl-0 "
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-primary"
                >
                  <feather-icon
                          size="24"
                          icon="TrendingUpIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                   {{ stats.total_outlets | number('0,0') }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Outlets
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
                  xl="4"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-danger"
                >
                  <feather-icon
                          size="24"
                          icon="BoxIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                   {{ stats.mpesa_checkouts | number('0,0') }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  M-pesa Parking Checkouts
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col
                  xl="4"
                  sm="6"
                  class="mb-2 mb-xl-0"
          >
            <b-media no-body>
              <b-media-aside

                      class="mr-2"
              >
                <b-avatar
                        size="48"
                        variant="light-info"
                >
                  <feather-icon
                          size="24"
                          icon="UserIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  {{ stats.wallet_checkouts | number('0,0') }}
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  Parking Loyalty Checkouts
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>

</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

import { mapGetters, mapActions } from "vuex";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import * as moment from 'moment'
import Vue2Filters from 'vue2-filters'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    DatePicker,
    Vue2Filters
  },
  data() {
    return {
      dashboard_filter:{
        start_date:"2023-07-01",
        end_date:""
      },
      stats:{
        redeemable_value:0,
        redeemed_value:0,
        total_points_accrued:0,
        total_customers:0,
        total_outlets:0,
        new_registrations:0,
        new_registrations_today:0,
        parking_checkouts:0,
        mpesa_checkouts:0,
        wallet_checkouts:0
      }
    }
  },
  mounted(){


    //this.dashboard_filter.start_date = moment().startOf("year").format('YYYY-MM-DD');
    this.dashboard_filter.end_date = moment().format('YYYY-MM-DD');

    this.getHomepageData();
    //this.getTopPerformingOutletsData();
  },
  methods: {
    ...mapActions(["getHomepageStats", "getTopPerformingOutlets"]),
    disabledDatesFrom(date){
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date > today ;
    },
    disabledDatesTo(date){
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today ;
    },
    getHomepageData(){
      let self = this;
      this.getHomepageStats({start_date:this.dashboard_filter.start_date, end_date:this.dashboard_filter.end_date })
              .then(function (stats) {

                self.stats = stats;
              })
              .catch(function (error) {
                if( typeof error.response.status != "undefined"){
                  if(error.response.status == 401){
                    //self.$router.push({ name: 'auth-login'})

                  }

                }
              })
    },
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

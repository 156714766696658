<template>
  <b-card
    no-body
    class="card-revenue-budget"
  >
    <b-row class="mx-0">
      <b-col
        md="12"
        class="revenue-report-wrapper"
      >
        <div class="d-sm-flex justify-content-between align-items-center mb-3">
          <h5 class="card-title mb-50 mb-sm-0">
            Quick Stats
          </h5>
        </div>

        <b-table striped hover :items="stats" :fields="columns">

          <!--<template #cell(no)="data">
            {{ data.index + 1 }}
          </template>-->

        </b-table>

      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BDropdown, BDropdownItem, BButton,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import {mapActions} from "vuex";

export default {
  components: {
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columns:['description','today',{
        key: 'mtd',
        label: 'Month to date',
        sortable: false
      },{
        key: 'ytd',
        label: 'Year to date',
        sortable: false
      }],
      startDate:'2023-07-01',
      endDate:'2023-07-18',
      transactionType:'ACCRUAL',
      stats:[]
    }
  },
  mounted (){
    this.getLoyaltyStatsData();
  },
  methods: {
    ...mapActions(["getLoyaltyQuickStats"]),

    getLoyaltyStatsData(){
      let self = this;

      this.isLoading = true;

      this.getLoyaltyQuickStats()
          .then(function (stats) {
            self.isLoading = false;
            self.stats = stats;

          })
          .catch(function (error) {

            self.isLoading = false;

            console.log(error)
          })
    },
  }
  }
</script>
